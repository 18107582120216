import OrderColumn from './OrderColumn';

export default class OrderColumns {
  orderColumns: OrderColumn[];

  constructor(value?: OrderColumn[]) {
    this.orderColumns = (value === undefined ? OrderColumns.create() : value);
  }

  public static create(): OrderColumn[] {
    const result: OrderColumn[] = [];
    return result;
  }

  findIndexOf(value: OrderColumn): number {
    return this.orderColumns.findIndex((orderColumn) => orderColumn.isEqualsValue(value.value));
  }

  isExists(value: OrderColumn): boolean {
    return this.findIndexOf(value) >= 0;
  }
}
