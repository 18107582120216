export default class OrderColumn {
  name: string;

  value: string;

  constructor(
    name?: string,
    value?: string
  ) {
    this.name = (name === undefined ? '' : name);
    this.value = (value === undefined ? '' : value);
  }

  isEqualsValue(value: string): boolean {
    return this.value === value;
  }

  static userId(): OrderColumn {
    return new OrderColumn('ID', 'userId');
  }

  static userName(): OrderColumn {
    return new OrderColumn('氏名', 'userName');
  }

  static company(): OrderColumn {
    return new OrderColumn('所属会社', 'company');
  }

  static primeContractor(): OrderColumn {
    return new OrderColumn('元請', 'primeContractor');
  }

  static workplace(): OrderColumn {
    return new OrderColumn('就業場所（最新）', 'workplace');
  }

  static startDate(): OrderColumn {
    return new OrderColumn('期間', 'startDate');
  }
}
