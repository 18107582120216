import { AxiosPromise } from 'axios';
import UserWorkplaceInfoClass from '@/model/user/Workplace/UserWorkplaceInfoClass';
import WorkplaceSearchConditions from '@/model/user/Workplace/search/WorkplaceSearchConditions';
import axios from '../AxiosBase';

export default {

  findList(searchConditions: WorkplaceSearchConditions): AxiosPromise<any> {
    return axios.post('admin/workplace/List', searchConditions);
  },

  download(searchConditions: WorkplaceSearchConditions): AxiosPromise<any> {
    return axios.post('admin/workplace/download', searchConditions);
  },

  findById(userId: string): AxiosPromise<any> {
    return axios.get(`admin/workplace/byId/${userId}`);
  },

  save(workplaceInfo: UserWorkplaceInfoClass): AxiosPromise<any> {
    return axios.post('admin/workplace/save', workplaceInfo);
  },

  update(workplaceInfo: UserWorkplaceInfoClass): AxiosPromise<any> {
    return axios.post('admin/workplace/update', workplaceInfo);
  },

  delete(workplaceId: string): AxiosPromise<any> {
    return axios.post(`admin/workplace/delete/${workplaceId}`);
  }
};
