import OrderColumns from './OrderColumns';

/**
 * 勤務先一覧検索条件
 */
export default class WorkplaceSearchConditions {
  /** 並べ替え項目 */
  orderColumns: OrderColumns;

  constructor(
    orderColumns?: OrderColumns
  ) {
    this.orderColumns = (orderColumns === undefined ? new OrderColumns() : orderColumns);
  }
}
