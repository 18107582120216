
import { Component, Vue } from 'vue-property-decorator';
import { NavigationGuardNext, Route } from 'vue-router';
import { Getter, Mutation } from 'vuex-class';
import Encoding from 'encoding-japanese';
import MultiSelect from 'vue-multiselect';
import WorkplaceListAPI from '@/module/api/WorkplaceApi';
import WorkplaceSearchConditions from '@/model/user/Workplace/search/WorkplaceSearchConditions';
import UserWorkplaceInfoClass from '@/model/user/Workplace/UserWorkplaceInfoClass';
import OrderColumn from '@/model/user/Workplace/search/OrderColumn';
import OrderColumns from '@/model/user/Workplace/search/OrderColumns';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave'
]);
@Component({
  components: {
    MultiSelect
  }
})
export default class Workplace extends Vue {
  searchConditions = new WorkplaceSearchConditions();
  userWorkplaceList:UserWorkplaceInfoClass[]=[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Getter('pageCache') getPageCache!: (key:string)=> any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Mutation('commitPageCache') commitPageCache!: any;
  orderColumns: OrderColumns = new OrderColumns();
  orderOptions: OrderColumn[] = [
    OrderColumn.userId(),
    OrderColumn.userName(),
    OrderColumn.company(),
    OrderColumn.primeContractor(),
    OrderColumn.workplace(),
    OrderColumn.startDate()
  ];

  /** 検索条件をキャッシュへ保存用 */
  displayPath: string = this.$route.path;

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext<Workplace>): void {
    next((vm: Workplace) => vm.rememberPageView());
  }
  rememberPageView(): void {
    const cache = this.getPageCache(this.displayPath);
    if (cache) {
      this.userWorkplaceList = cache.posts;
      this.searchConditions = cache.conditions;
    } else {
      this.clear();
    }
    this.setTypeParamForDisplay();
  }
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext<Workplace>): void {
    this.commitPageCache(
      {
        pageName: this.displayPath,
        value: {
          posts: this.userWorkplaceList,
          conditions: this.searchConditions,
        }
      }
    );
    next();
  }

  initialize():void {
    this.orderColumns = new OrderColumns(OrderColumns.create());
    this.orderColumns.orderColumns.push(OrderColumn.userId());
  }

  getData():void {
    this.setTypeParam();
    WorkplaceListAPI.findList(this.searchConditions).then((res) => {
      this.userWorkplaceList = res.data;
    });
  }

  search():void {
    this.getData();
  }

  clear():void{
    this.initialize();
    // 検索実行
    this.getData();
  }

  setTypeParamForDisplay():void {
    this.orderColumns.orderColumns = [];
    this.searchConditions.orderColumns.orderColumns.forEach((column) => {
      this.orderColumns.orderColumns.push(this.orderOptions.find((option) => option.isEqualsValue(column.value)) ?? new OrderColumn());
    });
  }
  setTypeParam():void{
    this.searchConditions.orderColumns.orderColumns = [];
    this.orderColumns.orderColumns.forEach((column) => {
      this.searchConditions.orderColumns.orderColumns.push(column);
    });
  }

  downloadcsv():void{
    this.setTypeParam();
    WorkplaceListAPI.download(this.searchConditions).then((res) => {
      const unicodeList = Encoding.stringToCode(res.data);
      const shiftJisCodeList = Encoding.convert(unicodeList, 'SJIS', 'UNICODE'); // J-JISへ変換
      const shiftJisString = new Uint8Array(shiftJisCodeList);

      const blob = new Blob([shiftJisString], { type: 'text/csv' });
      const fileNameKey: string = 'filename=';
      const contentDisposition = res.headers['content-disposition'];
      const fileName = contentDisposition.substring(contentDisposition.indexOf(fileNameKey) + fileNameKey.length, contentDisposition.length);
      const object = document.createElement('a');
      object.href = window.URL.createObjectURL(blob);
      object.download = fileName;
      document.body.appendChild(object);
      object.click();
      document.body.removeChild(object);
    });
  }
}
